











































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { useChatRoom } from '@/composition/myTalk';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import officialUsers from '@/composition/officialUser';
import FcDate from '@/components/FcDate.vue';
import FcImage from '@/components/FcImage.vue';
import FcAuthority from '@/components/FcAuthority.vue';
import FcColor from '@/components/FcColor.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'MyTalk',
  components: {
    FcDate,
    FcImage,
    FcAuthority,
    FcColor,
    FcStaticImage,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    chatroomId: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup(props, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('mytalks'));

    const chatRoom = useChatRoom(props.chatroomId);
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const isSaving = ref(false);
    const officialUserOptions = computed(() =>
      officialUsers.getOfficialUserOptions(true, '', myRoleSettings.value?.unrestrictedByLinkedOfficialUsers)
    );
    const title = context.root.$route.query.title || 'マイトーク';

    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        if (props.chatroomId) {
          await chatRoom.editChatRoom();
          notification.notify('変更しました。');
        } else {
          await chatRoom.createChatRoom();
          notification.notify('作成しました。');
          context.root.$router.push('/mytalks');
        }
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
    };

    return {
      pageTitle: title,
      myRoleSettings,
      chatRoom,
      save,
      isSaving,
      officialUsers,
      officialUserOptions,
    };
  },
});
