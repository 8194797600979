import core from '@/admin/core';
import myAttributes from '@/composition/myAttributes';
import { DisplayDate } from './util';

type ChatRoomStatus = 'scheduled' | 'active' | 'archive' | 'deleted';
// interface
export interface ChatRoom {
  chatRoomId: string;
  chatRoomStatus: ChatRoomStatus;
  title: string;
  thumbnail?: string;
  description?: string;
  accessStartDate?: number;
  accessEndDate?: number;
  officialUserId: string;
  target: ChatRoomTarget;
  createUser: string;
  updateUser?: string;
  createDate: number;
  updateDate?: number;
  isDeleted: boolean;
  isAccessStartedChatRoom: boolean;
}

export interface ChatRoomsProperty {
  chatRoomStatus: ChatRoomStatus;
  chatRoomId?: string;
  title: string;
  officialUserId: string;
  accessStartDate: DisplayDate;
  accessEndDate: DisplayDate;
  target: string;
}

export interface ChatRoomItem {
  title: string;
  thumbnail?: string;
  description: string;
  accessStartDate: DisplayDate;
  accessEndDate: DisplayDate;
  createDate: DisplayDate;
  officialUserId: string;
  target: ChatRoomPermission;
  isAccessStartedChatRoom: boolean;
  isAccessEndedChatRoom: boolean;
  targetPlanNames?: string[];
  targetSeasonName?: string;
}

export interface ChatRoomProps {
  title: string;
  thumbnail?: string;
  description?: string;
  accessStartDate?: number;
  accessEndDate?: number;
  officialUserId: string;
  target: ChatRoomTarget;
}

export interface ChatRoomTarget {
  usernames?: string[];
  subscriptionPlanIds?: string[];
  seasonIds?: number[];
  allMembers?: boolean;
}
export interface ChatRoomPermission {
  acceptAllMembers: boolean;
  planIds: string[];
  seasonIds: number[];
  userIds: string[];
}

export interface LatestChatMessage {
  chatMessageId: string;
  postedDate: number;
  body: string;
  isDeleted: string;
}
export interface ChatRoomUserResponse {
  username: string;
  nickname: string;
  latestChatMessage?: LatestChatMessage;
  isPendingAction: boolean; // 未対応フラグ
  joinDate: number;
}

export interface ChatRoomUserProps {
  chatRoomId: string;
  offset?: string;
  limit?: string;
  orderType?: '1' | '2';
  isPendingAction?: boolean;
}

export interface TargetProps {
  updateType: 'add' | 'delete';
  chatRoomId: string;
  target: ChatRoomTarget;
}

export interface CreateMessageProps {
  chatRoomId: string;
  body: string;
  images?: string[];
  movie?: string;
  voice?: string;
  replyTo?: {
    chatMessageId: string;
    postUser: string;
  };
  target?: {
    usernames: string[];
    allUsers: boolean;
  };
}

export interface MessageResponse {
  chatMessageId: string;
  chatRoomId: string;
  postUser: string;
  nickname: string;
  postedDate: number;
  body: string;
  images?: string[];
  movie?: string;
  voice?: string;
  replyTo?: {
    chatMessageId: string;
    postUser: string;
    postedDate: number;
    body: string;
  };
  reactions: {
    chatMessageId: string;
    postedDate: number;
    postUser: string;
    chatRoomId: string;
    username: string;
    reactionId: string;
    reactionDate: number;
  }[];
  target?: {
    usernames: string[];
    allUsers: boolean;
  };
  readDate?: number;
  createDate: number;
  isDeleted: boolean;
}

interface ReactionProps {
  chatMessageId: string;
  reactionId: string;
}

// method
export const getChatRooms = async (): Promise<ChatRoom[]> => {
  if (myAttributes.myRequestPermissions?.chatRooms) {
    try {
      const result = await core.httpClient.get('/admin/public/chatRooms');
      return result.data as ChatRoom[];
    } catch (e) {
      return [] as ChatRoom[];
    }
  } else return [] as ChatRoom[];
};

export const getChatRoom = async (chatRoomId: string): Promise<ChatRoom> => {
  if (myAttributes.myRequestPermissions?.chatRooms) {
    try {
      const result = await core.httpClient.get(`/admin/public/chatRooms/${chatRoomId}`);
      return result.data as ChatRoom;
    } catch (e) {
      return {} as ChatRoom;
    }
  } else return {} as ChatRoom;
};

export const createChatRoom = async (props: ChatRoomProps) => {
  await core.httpClient.post('/admin/public/chatRooms', props);
};

export const editChatRoom = async (props: ChatRoomProps, chatRoomId: string) => {
  await core.httpClient.put(`/admin/public/chatRooms/${chatRoomId}`, props);
};

export const deleteChatRoom = async (chatRoomId: string) => {
  await core.httpClient.delete(`/admin/public/chatRooms/${chatRoomId}`);
};

export const getChatRoomJoinUsers = async (props: ChatRoomUserProps) => {
  let query = '';
  Object.entries(props).forEach(([key, value]) => {
    if (key !== 'chatRoomId') query += `${key}=${value}&`;
  });
  query = query.slice(0, -1);
  if (myAttributes.myRequestPermissions?.chatRooms) {
    try {
      const result = await core.httpClient.get(`/admin/public/chatRooms/${props.chatRoomId}/chatRoomJoins?${query}`);
      return result.data as ChatRoomUserResponse[];
    } catch (e) {
      return [] as ChatRoomUserResponse[];
    }
  } else return [] as ChatRoomUserResponse[];
};

export const getChatRoomJoinUser = async (chatRoomId: string, username: string) => {
  if (myAttributes.myRequestPermissions?.chatRooms) {
    try {
      const result = await core.httpClient.get(`/admin/public/chatRooms/${chatRoomId}/chatRoomJoins/${username}`);
      return result.data as ChatRoomUserResponse;
    } catch (e) {
      return {} as ChatRoomUserResponse;
    }
  }
  return {} as ChatRoomUserResponse;
};

export const updateIsPendingActionOfChatRoomJoin = async (
  chatRoomId: string,
  username: string,
  isPendingAction: boolean
) => {
  await core.httpClient.request({
    method: 'PATCH',
    url: `/admin/public/chatRooms/${chatRoomId}/chatRoomJoins/${username}`,
    data: { isPendingAction },
  });
};

export const editTarget = async (targetName: string, props: TargetProps) => {
  const result = await core.httpClient.put(`/admin/public/chatRooms/target/${targetName}`, props);
  return result.data as { invalidUsernames: string[]; failedUsernames: string[] };
};

export const getMessages = async (props: { username: string; chatRoomId: string; endPostedDate?: number }) => {
  if (myAttributes.myRequestPermissions?.chatMessages) {
    const result = await core.httpClient.get(
      `/admin/public/chatMessages?username=${props.username}&chatRoomId=${props.chatRoomId}${
        props.endPostedDate ? `&endPostedDate=${props.endPostedDate}` : ''
      }`
    );
    return result.data as MessageResponse[];
  } else return [] as MessageResponse[];
};

export const createMessage = async (props: CreateMessageProps) => {
  const result = await core.httpClient.post('/admin/public/chatMessages', props);
  return result.data as { targetUserCount: number; notTargetUsernames: string[] };
};

export const deleteMessage = async (chatMessageId: string) => {
  await core.httpClient.delete(`/admin/public/chatMessages/${chatMessageId}`);
};

export const addReaction = async (props: ReactionProps) => {
  await core.httpClient.post('/admin/public/chatMessages/reactions', props);
};

export const deleteReaction = async (props: ReactionProps) => {
  await core.httpClient.delete(
    `/admin/public/chatMessages/reactions?chatMessageId=${props.chatMessageId}&reactionId=${props.reactionId}`
  );
};
