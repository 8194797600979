import { computed, ComputedRef, reactive, Ref, ref } from '@vue/composition-api';
import { DisplayDate, getTargetPlanNames, getTargetSeasonName } from '@/admin/util';
import { getSubscriptionPlans, SubscriptionPlan } from '@/admin/payment';
import { getSeasons, Season } from '@/admin/auth';
import officialUsers from '@/composition/officialUser';
import {
  LatestChatMessage,
  ChatRoomsProperty,
  ChatRoomTarget,
  ChatRoomItem,
  ChatRoomProps,
  getChatRooms,
  getChatRoom,
  createChatRoom,
  editChatRoom,
  deleteChatRoom,
  getChatRoomJoinUsers,
  ChatRoomUserProps,
  ChatRoomUserResponse,
  editTarget,
  TargetProps,
  getMessages,
  MessageResponse,
  CreateMessageProps,
  createMessage,
  deleteMessage,
  deleteReaction,
  addReaction,
  getChatRoomJoinUser,
  updateIsPendingActionOfChatRoomJoin,
} from '@/admin/chatRooms';

const DisplayChatRoomJoinUsersLimit = 100; // チャットルーム参加者表示件数
const GetChatRoomJoinUsersLimit = DisplayChatRoomJoinUsersLimit + 1; // チャットルーム参加者取得件数（表示件数＋1）

class LatestUserChatMessage {
  chatMessageId: string;
  postedDate: DisplayDate;
  body: string;
  isDeleted: string;
  constructor(props: LatestChatMessage) {
    this.chatMessageId = props.chatMessageId;
    this.postedDate = new DisplayDate(props.postedDate);
    this.body = props.body;
    this.isDeleted = props.isDeleted;
  }
}
export class ChatRoomUserProperty {
  username: string;
  nickname: string;
  isPendingAction: boolean;
  joinDate?: DisplayDate;
  latestUserChatMessage?: LatestUserChatMessage;
  constructor(
    username: string,
    nickname: string,
    isPendingAction: boolean,
    joinDate?: DisplayDate,
    latestUserChatMessage?: LatestChatMessage
  ) {
    this.username = username;
    this.nickname = nickname;
    this.isPendingAction = isPendingAction;
    this.joinDate = joinDate;
    this.latestUserChatMessage = latestUserChatMessage ? new LatestUserChatMessage(latestUserChatMessage) : undefined;
  }
}
interface ChatMessage {
  chatMessageId: string;
  chatRoomId: string;
  postedUsername: string;
  postedDate: DisplayDate;
  body: string;
  image?: string;
  movie?: string;
  voice?: string;
  replyTo?: {
    chatMessageId: string; // 投稿一覧のcsvで使用
    postedDate: DisplayDate;
    body: string;
  };
  reactions: string[];
  readDate: DisplayDate;
  createDate: DisplayDate;
  isDeleted: boolean;
  isPostAllUsers: boolean;
}

class ChatRooms {
  private _isLoading = ref(false);
  private _chatRooms: Ref<ChatRoomsProperty[]> = ref([]);

  get isLoading() {
    return this._isLoading.value;
  }
  get chatRooms() {
    return this._chatRooms.value;
  }

  constructor() {
    this.getChatRooms();
  }

  // 一覧に表示する公開範囲
  getTargetString = (target: ChatRoomTarget, plans: SubscriptionPlan[], seasons: Season[]) => {
    if (target.allMembers) return '無料含む全ての会員';
    else if (target.usernames) return 'ユーザー指定';
    else if (target.subscriptionPlanIds) {
      const _planNames = getTargetPlanNames(target.subscriptionPlanIds, plans);
      return _planNames.join(', ');
    } else if (target.seasonIds) return getTargetSeasonName(target.seasonIds, seasons);
    else return '';
  };

  getChatRooms = async () => {
    this._isLoading.value = true;
    await Promise.all([getSubscriptionPlans(), getSeasons(), getChatRooms()])
      .then(([plans, seasons, rooms]) => {
        this._chatRooms.value = rooms
          // アーカイブしたルームをフィルターして表示
          .filter((room) => !room.isDeleted)
          // 降順ソート
          .sort((a, b) => b.createDate - a.createDate)
          // 整形
          .map((room) => {
            return {
              chatRoomStatus: room.chatRoomStatus,
              chatRoomId: room.chatRoomId,
              title: room.title,
              officialUserId: room.officialUserId,
              accessStartDate: new DisplayDate(room.accessStartDate),
              accessEndDate: new DisplayDate(room.accessEndDate),
              createDate: new DisplayDate(room.createDate),
              target: this.getTargetString(room.target, plans, seasons),
            };
          });
      })
      .finally(() => (this._isLoading.value = false));
  };

  deleteChatRoom = async (ChatRoomId: string) => {
    await deleteChatRoom(ChatRoomId);
  };
}

class ChatRoom {
  private _isLoading = ref(false);
  private _isAccessStartDate = ref(false);
  private _isAccessEndDate = ref(false);
  private _chatRoomId = ref('');

  item: ChatRoomItem = reactive({
    title: '',
    thumbnail: '',
    description: '',
    accessStartDate: new DisplayDate(),
    accessEndDate: new DisplayDate(),
    createDate: new DisplayDate(),
    officialUserId: '',
    target: { acceptAllMembers: false, planIds: [], seasonIds: [], userIds: [] },
    isAccessStartedChatRoom: false,
    isAccessEndedChatRoom: false,
  });

  get isLoading() {
    return this._isLoading.value;
  }
  get isAccessStartDate() {
    return this._isAccessStartDate.value;
  }
  set isAccessStartDate(_isAccessStartDate: boolean) {
    this._isAccessStartDate.value = _isAccessStartDate;
  }
  get isAccessEndDate() {
    return this._isAccessEndDate.value;
  }
  set isAccessEndDate(_isAccessEndDate: boolean) {
    this._isAccessEndDate.value = _isAccessEndDate;
  }

  constructor(chatRoomId?: string) {
    if (chatRoomId) {
      this._chatRoomId.value = chatRoomId;
      this.getChatRoom(chatRoomId);
    }
  }

  getChatRoom = async (chatRoomId: string) => {
    this._isLoading.value = true;

    const _item = await getChatRoom(chatRoomId);

    // 整形
    const now = Date.now();
    this.item = {
      title: _item.title,
      thumbnail: _item.thumbnail || '',
      description: _item.description || '',
      accessStartDate: new DisplayDate(_item.accessStartDate),
      accessEndDate: new DisplayDate(_item.accessEndDate),
      createDate: new DisplayDate(_item.createDate),
      officialUserId: _item.officialUserId,
      target: {
        acceptAllMembers: !!_item.target.allMembers,
        planIds: _item.target.subscriptionPlanIds || [],
        seasonIds: _item.target.seasonIds || [],
        userIds: _item.target.usernames || [],
      },
      isAccessStartedChatRoom: _item.isAccessStartedChatRoom,
      isAccessEndedChatRoom: !!_item.accessEndDate && _item.accessEndDate <= now,
    };

    if (this.item.accessStartDate.value) this.isAccessStartDate = true;
    if (this.item.accessEndDate.value) this.isAccessEndDate = true;

    // プラン名とシーズン名の取得
    if (this.item.target.planIds.length) {
      const plans = await getSubscriptionPlans();
      this.item.targetPlanNames = getTargetPlanNames(this.item.target.planIds, plans);
    }
    if (this.item.target.seasonIds.length) {
      const seasons = await getSeasons();
      this.item.targetSeasonName = getTargetSeasonName(this.item.target.seasonIds, seasons);
    }

    this._isLoading.value = false;
  };

  createProps = () => {
    if (
      !this.item.title ||
      !this.item.officialUserId ||
      (!this.item.target.acceptAllMembers &&
        !this.item.target.planIds.length &&
        !this.item.target.seasonIds.length &&
        !this.item.target.userIds.length)
    ) {
      throw '必須項目を全て入力してください';
    } else if (this.isAccessStartDate && !this.item.accessStartDate.value) {
      throw '公開開始日を正しく入力してください';
    } else if (this.isAccessEndDate && !this.item.accessEndDate.value) {
      throw '公開終了日を正しく入力してください';
    } else if (
      this.isAccessStartDate &&
      this.isAccessEndDate &&
      this.item.accessEndDate.value &&
      this.item.accessStartDate.value &&
      this.item.accessEndDate.value <= this.item.accessStartDate.value
    ) {
      throw '公開終了日が公開開始日より前に設定されています';
    } else {
      const props: ChatRoomProps = {
        title: this.item.title,
        officialUserId: this.item.officialUserId,
        target: {},
      };
      if (this.item.thumbnail) props.thumbnail = this.item.thumbnail;
      if (this.item.description) props.description = this.item.description;
      if (this.isAccessStartDate) props.accessStartDate = this.item.accessStartDate.value;
      if (this.isAccessEndDate) props.accessEndDate = this.item.accessEndDate.value;
      if (this.item.target.userIds.length) props.target.usernames = this.item.target.userIds;
      if (this.item.target.planIds.length) props.target.subscriptionPlanIds = this.item.target.planIds;
      if (this.item.target.seasonIds.length) props.target.seasonIds = this.item.target.seasonIds;
      if (this.item.target.acceptAllMembers) props.target.allMembers = true;
      return props;
    }
  };

  createChatRoom = async () => {
    const props = this.createProps();
    if (props) await createChatRoom(props);
  };

  editChatRoom = async () => {
    const props = this.createProps();
    if (props) await editChatRoom(props, this._chatRoomId.value);
  };
}

class ChatRoomUser {
  chatRoomId = ref('');
  username = ref('');
  nickname = ref('');
  isPendingAction = ref(false);
  joinDate = ref(new DisplayDate());
  latestUserChatMessage: Ref<LatestUserChatMessage | undefined> = ref(undefined);
  constructor(chatRoomId: string, username: string) {
    this.chatRoomId.value = chatRoomId;
    this.username.value = username;
  }

  getChatRoomUser = async () => {
    const _chatRoomUser = await getChatRoomJoinUser(this.chatRoomId.value, this.username.value);
    this.username.value = _chatRoomUser.username;
    this.nickname.value = _chatRoomUser.nickname;
    this.isPendingAction.value = _chatRoomUser.isPendingAction;
    this.joinDate.value = new DisplayDate(_chatRoomUser.joinDate);
    this.latestUserChatMessage.value = _chatRoomUser.latestChatMessage
      ? new LatestUserChatMessage(_chatRoomUser.latestChatMessage)
      : _chatRoomUser.latestChatMessage;
  };

  updateIsPendingAction = async (isPendingAction: boolean) => {
    await updateIsPendingActionOfChatRoomJoin(this.chatRoomId.value, this.username.value, isPendingAction);
    this.isPendingAction.value = isPendingAction;
  };
}
class ChatRoomUsers {
  private _isInitializing = ref(true);
  private _isLoading = ref(false);
  private _chatRoomId = ref('');
  private _chatRoomTargetType = ref('');
  private _chatRoomTitle = ref('');
  private _chatRoomStatus = ref('');
  private _nextOffset = ref('');
  private _joinUsersData: Ref<ChatRoomUserResponse[]> = ref([]);
  private _joinUsers: ComputedRef<ChatRoomUserProperty[]> = computed(() => {
    return this._joinUsersData.value.map((joinUserData) => {
      return new ChatRoomUserProperty(
        joinUserData.username,
        joinUserData.nickname,
        joinUserData.isPendingAction,
        new DisplayDate(joinUserData.joinDate),
        joinUserData.latestChatMessage
      );
    });
  });
  private _usersOptions: ComputedRef<Option[]> = computed(() =>
    this._joinUsers.value.map((join) => {
      return { text: `${join.nickname}【${join.username}】`, value: join.username };
    })
  );

  get isInitializing() {
    return this._isInitializing.value;
  }
  set isInitializing(isInitializing: boolean) {
    this._isInitializing.value = isInitializing;
  }
  get isLoading() {
    return this._isLoading.value;
  }
  get joinUsers() {
    return this._joinUsers.value;
  }
  get hasNextUsers() {
    return !!this._nextOffset.value;
  }
  get chatRoomTargetType() {
    return this._chatRoomTargetType.value;
  }
  get chatRoomTitle() {
    return this._chatRoomTitle.value;
  }
  get chatRoomStatus() {
    return this._chatRoomStatus.value;
  }
  get usersOptions() {
    return this._usersOptions.value;
  }

  constructor(chatRoomId?: string) {
    if (!chatRoomId) return;
    this._chatRoomId.value = chatRoomId;
    Promise.all([this.getChatRoomUsers(), this.getChatRoomInfo()]).finally(() => (this._isInitializing.value = false));
  }

  getChatRoomUsers = async (
    props: { isNextUsers?: boolean; isPendingAction?: boolean } = { isNextUsers: false, isPendingAction: undefined }
  ) => {
    this._isLoading.value = true;
    const isNextUsers = props.isNextUsers;
    const isPendingAction = props.isPendingAction;

    const _props: ChatRoomUserProps = {
      chatRoomId: this._chatRoomId.value,
      limit: String(GetChatRoomJoinUsersLimit),
      orderType: '2',
      isPendingAction,
    };
    // もっと読み込むの場合propsにoffsetを設定
    if (isNextUsers && this._nextOffset.value) _props.offset = this._nextOffset.value;
    // それ以外の場合はoffsetをリセットする
    else if (this._nextOffset.value) this._nextOffset.value = '';

    await getChatRoomJoinUsers(_props)
      .then((_joinUsers) => {
        // 表示件数分データを保管
        this._joinUsersData.value = isNextUsers
          ? [...this._joinUsersData.value, ..._joinUsers.slice(0, DisplayChatRoomJoinUsersLimit)]
          : _joinUsers.slice(0, DisplayChatRoomJoinUsersLimit);
        // リクエスト数（limit）と取得数が一致していたら、続きがあるので_nextOffsetを上書き、なければ空にする。
        if (_joinUsers.length === GetChatRoomJoinUsersLimit) {
          this._nextOffset.value = String(this._joinUsersData.value.length);
        } else if (this._nextOffset.value) {
          this._nextOffset.value = '';
        }
      })
      .finally(() => (this._isLoading.value = false));
  };
  getChatRoomInfo = async () => {
    const chatRoom = await getChatRoom(this._chatRoomId.value);
    if (chatRoom.target.allMembers) this._chatRoomTargetType.value = 'allMembers';
    else if (chatRoom.target.subscriptionPlanIds?.length) this._chatRoomTargetType.value = 'subscriptionPlans';
    else if (chatRoom.target.seasonIds?.length) this._chatRoomTargetType.value = 'seasons';
    else this._chatRoomTargetType.value = 'usernames';
    this._chatRoomTitle.value = chatRoom.title;
    this._chatRoomStatus.value = chatRoom.chatRoomStatus;
  };

  addUsers = async (usernames: string[]) => {
    const props: TargetProps = {
      updateType: 'add',
      chatRoomId: this._chatRoomId.value,
      target: {
        usernames,
      },
    };
    const targetType = 'usernames';
    let invalidUsernames: string[] = [];
    let failedUsernames: string[] = [];
    try {
      const result = await editTarget(targetType, props);
      invalidUsernames = result.invalidUsernames;
      failedUsernames = result.failedUsernames;
    } catch (e) {
      console.error(e);
    }
    return [invalidUsernames, failedUsernames];
  };
  deleteUsers = async (usernames: string[]) => {
    const props: TargetProps = {
      updateType: 'delete',
      chatRoomId: this._chatRoomId.value,
      target: {
        usernames,
      },
    };
    const targetType = 'usernames';
    let invalidUsernames: string[] = [];
    let failedUsernames: string[] = [];

    const result = await editTarget(targetType, props);
    invalidUsernames = result.invalidUsernames;
    failedUsernames = result.failedUsernames;

    return [invalidUsernames, failedUsernames];
  };

  createPostProps = (formData: { target: 'all' | 'users' | null; users: string[]; body: string; image: string }) => {
    if (!formData.target) throw '投稿先を指定してください';
    if (formData.target === 'users' && !formData.users.length) throw 'ユーザーを指定してください';
    if (!formData.body) throw '投稿内容を入力してください';
    const props: CreateMessageProps = {
      chatRoomId: this._chatRoomId.value,
      body: formData.body,
      target: {
        usernames: formData.target === 'users' ? formData.users : [],
        allUsers: formData.target === 'all',
      },
    };
    if (formData.image) props.images = [formData.image];

    return props;
  };
  postMessage = async (formData: { target: 'all' | 'users' | null; users: string[]; body: string; image: string }) => {
    const props = this.createPostProps(formData);
    const result = await createMessage(props);
    const response = {
      type: '',
      message: '',
    };
    // 送信できないユーザーがいた場合
    if (result.notTargetUsernames.length) {
      response.type = 'warning';
      const notTargetMessage = result.notTargetUsernames.length
        ? `${result.notTargetUsernames.join(',')}は権限がないため、投稿できませんでした`
        : '';
      response.message = notTargetMessage;
    } else {
      response.type = 'success';
      response.message = '投稿しました';
    }
    return response;
  };
}

class Messages {
  private _isInitializing = ref(true);
  private _isLoading = ref(false);
  private _isSending = ref(false);
  private _chatRoomId = ref('');
  private _userId = ref('');
  private _endPostedDate = ref(0);
  private _chatRoomStatus = ref('');
  private _chatRoom: ChatRoomItem = reactive({
    title: '',
    thumbnail: '',
    description: '',
    accessStartDate: new DisplayDate(),
    accessEndDate: new DisplayDate(),
    createDate: new DisplayDate(),
    officialUserId: '',
    target: { acceptAllMembers: false, planIds: [], seasonIds: [], userIds: [] },
    isAccessStartedChatRoom: false,
    isAccessEndedChatRoom: false,
  });
  private _messages: Ref<MessageResponse[]> = ref([]);
  private _items: ComputedRef<ChatMessage[]> = computed(() => {
    return this._messages.value.map((item) => {
      const officialUser = officialUsers.getOfficialUser(item.postUser);
      return {
        chatMessageId: item.chatMessageId,
        chatRoomId: item.chatRoomId,
        isOfficialUser: !!officialUser,
        postedUsername: officialUser ? officialUser.name : item.nickname,
        officialUserColor: officialUser ? officialUser.color : '',
        postedDate: new DisplayDate(item.postedDate),
        body: item.body,
        image: item.images ? item.images[0] : undefined,
        movie: item.movie || undefined,
        voice: item.voice || undefined,
        replyTo: item.replyTo
          ? {
              chatMessageId: item.replyTo.chatMessageId,
              postedDate: new DisplayDate(item.replyTo.postedDate),
              body: item.replyTo.body,
            }
          : undefined,
        reactions: item.reactions.map((reaction) => reaction.reactionId),
        readDate: new DisplayDate(item.readDate),
        createDate: new DisplayDate(item.createDate),
        isPostAllUsers: !!item.target?.allUsers,
        isDeleted: item.isDeleted,
      };
    });
  });

  get isInitializing() {
    return this._isInitializing.value;
  }
  set isInitializing(_isInitializing) {
    this._isInitializing.value = _isInitializing;
  }
  get isLoading() {
    return this._isLoading.value;
  }
  get isSending() {
    return this._isSending.value;
  }
  set isSending(_isSending) {
    this._isSending.value = _isSending;
  }
  get chatRoomId() {
    return this._chatRoomId.value;
  }
  set chatRoomId(_chatRoomId) {
    this._chatRoomId.value = _chatRoomId;
  }
  get userId() {
    return this._userId.value;
  }
  set userId(_userId) {
    this._userId.value = _userId;
  }
  get hasNextMessages() {
    return !!this._endPostedDate.value;
  }
  get items() {
    return this._items.value;
  }
  get chatRoomStatus() {
    return this._chatRoomStatus.value;
  }
  get chatRoom() {
    return this._chatRoom;
  }
  set chatRoom(_chatRoom) {
    this._chatRoom = _chatRoom;
  }

  constructor(chatRoomId: string, userId: string) {
    this.chatRoomId = chatRoomId;
    this.userId = userId;
    Promise.all([this.getMessages(), this.getChatRoom()]).finally(() => (this.isInitializing = false));
  }

  getMessages = async (isNextMessages = false) => {
    this._isLoading.value = true;

    const props: {
      username: string;
      chatRoomId: string;
      endPostedDate?: number;
    } = {
      username: this.userId,
      chatRoomId: this.chatRoomId,
    };
    // もっと読み込むの場合propsにendPostedDateを入れる
    if (isNextMessages && this._endPostedDate.value) props.endPostedDate = this._endPostedDate.value;
    // それ以外の場合はendPostedDateをリセットする
    else if (this._endPostedDate.value) this._endPostedDate.value = 0;

    // メッセージを取得
    await getMessages(props)
      .then((data) => {
        const messages = data;
        // 最大〜100件目までを保管
        this._messages.value = isNextMessages
          ? [...this._messages.value, ...messages.slice(0, 100)]
          : messages.slice(0, 100);
        // 101件ある場合：101件目のpostedDateを保管、101件未満の場合：endPostedDateを空にする
        if (messages.length === 101) this._endPostedDate.value = messages[100].postedDate;
        else if (this._endPostedDate.value) this._endPostedDate.value = 0;
      })
      .finally(() => (this._isLoading.value = false));
  };
  getChatRoom = async () => {
    try {
      const item = await getChatRoom(this.chatRoomId);
      // 整形
      const now = Date.now();
      this.chatRoom = {
        title: item.title,
        thumbnail: item.thumbnail,
        description: item.description || '',
        accessStartDate: new DisplayDate(item.accessStartDate),
        accessEndDate: new DisplayDate(item.accessEndDate),
        createDate: new DisplayDate(item.createDate),
        officialUserId: item.officialUserId,
        target: {
          acceptAllMembers: !!item.target.allMembers,
          planIds: item.target.subscriptionPlanIds || [],
          seasonIds: item.target.seasonIds || [],
          userIds: item.target.usernames || [],
        },
        isAccessStartedChatRoom: item.isAccessStartedChatRoom,
        isAccessEndedChatRoom: !!item.accessEndDate && item.accessEndDate <= now,
      };
      this._chatRoomStatus.value = item.chatRoomStatus;
    } catch (e) {
      console.error(e);
    }
  };

  createPostProps = (formData: {
    replyMessage: { chatMessageId: string; body: string } | null;
    body: string;
    image: string;
  }) => {
    if (!formData.body) throw '本文を入力してください';
    const props: CreateMessageProps = {
      chatRoomId: this.chatRoomId,
      body: formData.body,
    };
    if (formData.image) props.images = [formData.image];

    // 返信
    if (formData.replyMessage) {
      props.replyTo = { chatMessageId: formData.replyMessage.chatMessageId, postUser: this.userId };
    } else {
      // 新規投稿
      props.target = {
        usernames: [this.userId],
        allUsers: false,
      };
    }
    return props;
  };

  post = async (formData: {
    replyMessage: { chatMessageId: string; body: string } | null;
    body: string;
    image: string;
  }) => {
    const props = this.createPostProps(formData);

    const result = await createMessage(props);
    const response = {
      type: '',
      message: '',
    };
    // 送信できないユーザーがいた場合
    if (result.notTargetUsernames.length) {
      response.type = 'warning';
      const notTargetMessage = result.notTargetUsernames.length
        ? `${result.notTargetUsernames.join(',')}は権限がないため、投稿できませんでした`
        : '';
      response.message = notTargetMessage;
    } else {
      response.type = 'success';
      response.message = '投稿しました';
    }
    return response;
  };

  deleteMessage = async (chatMessageId: string) => {
    try {
      await deleteMessage(chatMessageId);
    } catch (e) {
      console.error(e);
    }
  };

  createReactionProps = (reactionData: {
    chatMessageId: string;
    reactions: {
      reactionId: string;
      icon: string;
      isSelected: boolean;
      isAddedReaction: boolean;
    }[];
  }) => {
    // 削除するリアクション
    const reactionsDelete = [...reactionData.reactions]
      .filter((item) => !item.isSelected && item.isAddedReaction)
      .map((item) => ({
        chatMessageId: reactionData.chatMessageId,
        reactionId: item.reactionId,
      }));

    // 追加するリアクション
    const reactionsAdd = [...reactionData.reactions]
      .filter((item) => item.isSelected && !item.isAddedReaction)
      .map((item) => ({
        chatMessageId: reactionData.chatMessageId,
        reactionId: item.reactionId,
      }));

    if (!reactionsDelete.length && !reactionsAdd.length) throw '変更がありません';

    return {
      reactionsDelete,
      reactionsAdd,
    };
  };

  setReactions = async (reactionData: {
    chatMessageId: string;
    reactions: {
      reactionId: string;
      icon: string;
      isSelected: boolean;
      isAddedReaction: boolean;
    }[];
  }) => {
    const props = this.createReactionProps(reactionData);
    try {
      const reactionsPromise: Promise<void>[] = [];
      if (props.reactionsDelete.length)
        props.reactionsDelete.forEach((item) => {
          if (!item) return;
          reactionsPromise.push(deleteReaction(item));
        });
      if (props.reactionsAdd.length) props.reactionsAdd.forEach((item) => reactionsPromise.push(addReaction(item)));

      await Promise.all(reactionsPromise);
    } catch (e) {
      console.error(e);
    }
  };

  // CSV DL用
  csvLabels = {
    postedDate: '投稿日時',
    postedUsername: '投稿者',
    body: '本文',
    image: '添付画像',
    reactions: 'リアクション',
    chatMessageId: 'メッセージID',
    replyTo: '返信先メッセージID',
  };

  getCsvItems = () => {
    return this._items.value.map((item) => ({
      chatMessageId: item.chatMessageId,
      postedUsername: item.postedUsername,
      postedDate: item.postedDate.dateTime,
      body: item.body,
      image: item.image ? 'あり' : 'なし',
      replyTo: item.replyTo?.chatMessageId || '',
      reactions: item.reactions.length ? 'あり' : 'なし',
    }));
  };
}

export const useChatRooms = () => {
  return new ChatRooms();
};

export const useChatRoom = (chatRoomId?: string) => {
  return new ChatRoom(chatRoomId);
};

export const useChatRoomUser = (chatRoomId: string, username: string) => {
  return new ChatRoomUser(chatRoomId, username);
};

export const useChatRoomUsers = (chatRoomId?: string) => {
  return new ChatRoomUsers(chatRoomId);
};

export const useMessages = (chatRoomId: string, userId: string) => {
  return new Messages(chatRoomId, userId);
};
